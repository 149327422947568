<template>
  <div>
    <v-chart :options="options" :style="{height:height,width:width}" />
  </div>
</template>

<script>
import { analysisitem } from "@api/analysis";
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/markLine";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import "echarts/lib/component/visualMap";
import "echarts/lib/component/dataset";

export default {
  name: "WzChart",
  components: {
    "v-chart": ECharts,
  },
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "280px",
    },
  },
  data() {
    return {
      show: false,
      constitution: "",
      totalConversion: "", //转化总分
      totalOriginal: "", //原始总分
      crdList: [],
      pageloading: true,
      propose: "", //健康建议
      tendency: "",
      options: {
        title: { text: "体质得分", left: "center", top: 10 },
        legend: {
          data: ["体质得分", "我的体质"],
          selectedMode: false,
          icon: "circle",
          show: false,
        },

        xAxis: {
          type: "category",
          data: [
            "平和质",
            "气虚质",
            "阳虚质",
            "阴虚质",
            "痰湿质",
            "湿热质",
            "血瘀质",
            "气郁质",
            "特禀质",
          ],
          axisLabel: {
            color: "#333",
            //  让x轴文字方向为竖向
            interval: 0,
            formatter: function (value) {
              return value.split("").join("\n");
            },
          },

          axisTick: {
            show: false,
          },
        },
        yAxis: {
          show: true,
          type: "value",
          max: 80,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },

        series: [
          {
            name: "体质得分",
            data: [],
            type: "bar",
            itemStyle: {
              color: "rgba(0,140, 56, 1)",
            },
            showBackground: false,
            backgroundStyle: {
              color: "rgba(220, 220, 220, 0.8)",
            },
            label: {
              show: false,
            },

            barWidth: 20,
          },
          {
            name: "我的体质",
            data: [],
            type: "bar",
            itemStyle: {
              color: "#248cca",
            },
            showBackground: false,
            backgroundStyle: {
              color: "rgba(220, 220, 220, 0.8)",
            },
            label: {
              show: false,
            },

            barWidth: 1,
          },
        ],
      },
    };
  },
  computed: {},
  mounted() {
    this.setData();
  },
  methods: {
    //获取报告详情
    setData() {
      let data = [
        { value: this.chartData.pingheNum },
        { value: this.chartData.qixuNum },
        { value: this.chartData.yangxuNum },
        { value: this.chartData.yinxuNum },
        { value: this.chartData.tanshiNum },
        { value: this.chartData.shireNum },
        { value: this.chartData.xueyuNum },
        { value: this.chartData.qiyuNum },
        { value: this.chartData.tebingNum },
      ];

      this.options.series[0].data = data.map((item) => {
        let { value, itemStyle } = item;
        if (item.value >= 60 && item.value <= 100) {
          itemStyle = { color: " #0CA74D " };
        } else if (item.value < 60 && item.value >= 30) {
          itemStyle = { color: "#b1e5c6" };
        } else {
          itemStyle = { color: "#b1e5c6" };
        }
        return { value, itemStyle };
      });
      
    },
  },
};
</script>
