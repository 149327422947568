<template>
  <div>
    <div>
      <div class="middletitle">舌诊图片</div>
      <div class="we-bg-color we-text-p15 text-align-c">
        <van-image width="200" height="200"  fit="contain" :src="szreport.analysisImage" />
      </div>
    </div>
    <div class="content-wrap overhidden">
      <div class="middletitle">诊断结论</div>
      <table width="100%" class="text-align-c realtable">
        <tr>
          <td rowspan="3" width="30%">舌质</td>
          <td width="40%">舌色</td>
          <td width="40%">{{szreport.shese}}</td>
        </tr>
        <tr>
          <td>舌尖</td>
          <td>{{szreport.shejian}}</td>
        </tr>
        <tr>
          <td>舌状</td>
          <td>{{szreport.shezhuang}}</td>
        </tr>
        <tr>
          <td rowspan="2">舌苔</td>
          <td>苔色</td>
          <td>{{szreport.taise}}</td>
        </tr>
        <tr>
          <td>苔质</td>
          <td>{{szreport.taizhi}}</td>
        </tr>
      </table>

      <table width="100%" class="text-align-c realtable">
        <tr>
          <td colspan="4">舌象特征提示</td>
        </tr>
        <tr>
          <td width="30%">
            舌色指数
            <br />（淡白）
          </td>
          <td width="20%">{{szreport.sheseZs}}</td>
          <td width="20%">{{szreport.sheseMs}}</td>
          <td width="30%">{{szreport.sheseCk}}</td>
        </tr>
        <tr>
          <td>
            苔色指数
            <br />（黄苔）
          </td>
          <td>{{szreport.taiseZs}}</td>
          <td>{{szreport.taiseMs}}</td>
          <td>{{szreport.taiseCk}}</td>
        </tr>
        <tr>
          <td>胖瘦指数</td>
          <td>{{szreport.pangshouZs}}</td>
          <td>{{szreport.pangshouMs}}</td>
          <td>{{szreport.pangshouCk}}</td>
        </tr>
        <tr>
          <td>厚薄指数</td>
          <td>{{szreport.houbaoZs}}</td>
          <td>{{szreport.houbaoMs}}</td>
          <td>{{szreport.houbaoCk}}</td>
        </tr>
        <tr>
          <td>腐腻指数</td>
          <td>{{szreport.funiZs}}</td>
          <td>{{szreport.funiMs}}</td>
          <td>{{szreport.funiCk}}</td>
        </tr>
        <tr>
          <td>老嫩指数</td>
          <td>{{szreport.laonenZs}}</td>
          <td>{{szreport.laonenMs}}</td>
          <td>{{szreport.laonenCk}}</td>
        </tr>
      </table>
    </div>
    <div>
      <div class="middletitle">舌像分析图</div>
      <div class="we-bg-color chart-wrap">
        <v-chart :options="options"  />
        <div class="tsbs">
          <ul>
            <li style="left:46%;">1.00
              <span></span>
            </li>
          </ul>
          <ul>
            <li style="left:46%;">1.00<span></span>
            </li>
          </ul>
          <ul>
            <li style="left:9%;text-align: center">瘦小
            </li>
            <li style="left:17%;text-align: center">0.39<span></span>
            </li>
            <li style="left:35%;text-align: center">正常
            </li>
            <li style="left:46%;">1.00<span></span>
            </li>
            <li style="left:75%;">胖大
            </li>
          </ul>
          <ul>
            <li style="left:5%;text-align: center">少苔
            </li>
            <li style="left:11%;">0.22<span></span>
            </li>
            <li style="left:30%;">薄苔
            </li>
            <li style="left:46%;">1.00<span></span>
            </li>
            <li style="left:75%;">厚苔
            </li>
          </ul>
          <ul>
            <li style="left:25%;">正常
            </li>
            <li style="left:46%;">1.00<span></span>
            </li>
            <li style="left:75%;">腻苔
            </li>
          </ul>
          <ul>
            <li style="left:20%;">嫩舌
            </li>
            <li style="left:39%;">0.82<span></span>
            </li>
            <li style="left:43%;">正常
            </li>
            <li style="left:46%;">1.00<span></span>
            </li>
            <li style="left:75%;">老舌
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {
  Image as VanImage,
} from "vant";
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/markLine";
import "echarts/lib/component/graphic";

export default {
  name: "reporttongue",
  components: {
    
    [VanImage.name]: VanImage,
    "v-chart": ECharts,

    // [DatePicker.name]: DatePicker,
    // [Input.TextArea.name]: Input.TextArea,
    // [Select.Option.name]: Select.Option,
  },
  props: {
    szreport: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      options: {
        grid:{left:10,right:10,top:0},
        yAxis: {
          show: false,
          type: "category",
          data: [
            "老嫩指数",
            "腐腻指数",
            "厚薄指数",
            "胖瘦指数",
            "苔色指数(黄苔)",
            "舌色指数(淡白)",
          ],
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          // offset: -100,
          max: 5,

          // barCateGoryGap:50
        },
        xAxis: {
          type: "value",
          splitNumber: 10,
          min: 0,
          max: 2,
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(0, 0, 0, 0.1)",
              type: "dashed",
            },
          },
        },
        series: [
          {
            data: [],
            type: "bar",
            barWidth: 24,
            barCategoryGap: "70",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(177, 229, 198, 1)",
            },
            label: {
              show: true,
              formatter: "{b}                   {x|{@score}}",
              // distance: 50,
              rich: {
                a: {
                  color: "#fff",
                },
                x: {
                  fontSize: 12,
                  fontFamily: "Microsoft YaHei",
                  borderColor: "#449933",
                  padding: [4, 8],
                  borderRadius: 4,
                  backgroundColor: "#01D78E",
                  color: "#fff",
                },
              },
              position: "insideLeft",
              textBorderColor: "rgba(215, 61, 61, 0)",
            },
            itemStyle: {
              normal: {
                color: new ECharts.graphic.LinearGradient(1, 0, 0, 0, [
                  { offset: 0, color: "#00d68b" },
                  { offset: 1, color: "#009c31" },
                ]),
              },
            },
          },
        ],
       
        
      },
    };
  },
  computed: {},

  methods: {
    
   
  },
  mounted() {
    var chartData = [
        this.szreport.laonenZs,
        this.szreport.funiZs,
        this.szreport.houbaoZs,
        this.szreport.pangshouZs,
        this.szreport.taiseZs,
        this.szreport.sheseZs,
      ];
    this.options.series[0].data=chartData
  },
};
</script>
<style lang="less" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 100%;
  height: 420px;
  padding: 15px;
  background-color: #fff;
}
.van-grid-item__content {
  padding: 0.2rem;
}

.realtable {
  width: 100%;
  padding: 10px;
  tr:nth-child(odd) {
    background-color: #fff;
    td {
      height: 40px;
      background-color: #baf7c4;
      color: #3f3f3f;
    }
  }
  tr:nth-child(even) {
    td {
      height: 40px;
      background-color: #deffe3;
      color: #3f3f3f;
    }
  }
}
.chart-wrap {
  position: relative;
  width: 100%;
  height: 460px;
  background-color: #fff;
  .echarts {
    width: 100%;
    height: 460px;
    // position: relative;
    // left: -20px;
  }
}
.tsbs {
  font-size: 12px;
  position: absolute;
  top: 5px;
  // left: 8.1%;
  /*right:90px;*/
  // padding-left: 10px;
  width: 100%;
  -moz-print-color-adjust: exact;
  -ms-print-color-adjust: exact;
  print-color-adjust: exact;
  /*background-color: #1bbdae;*/

  ul {
    width: 100%;
    height: 66px;
    position: relative;
    -moz-print-color-adjust: exact;
    -ms-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  ul li {
    float: left;
    /*width: 60px;*/
    text-align: center;
    font-size: 10px;
    position: absolute;
    /*top: -70px;*/
    -moz-print-color-adjust: exact;
    -ms-print-color-adjust: exact;
    print-color-adjust: exact;
    list-style: none;
  }

  ul li span {
    display: block;
    width: 1px;
    height: 16px;
    border-left: 2px dashed #cceed6;
    position: relative;
    left: 13px;
    top: 10px;
    -moz-print-color-adjust: exact;
    -ms-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
</style>