<template>
  <v-chart :options="options" style="height:270px;width:100%" />
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/markLine";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import "echarts/lib/component/visualMap";
import "echarts/lib/component/dataset";

export default {
  // mixins: [resize],
  components: {
    "v-chart": ECharts,
  },
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      options: {
        title: {
          text: this.chartdata.title,
          x: "center",
          y: 0,
          textStyle: {
            fontSize: 16,
          },
        },
        legend: {
          orient: "vertical",
          selectedMode: false,
          color: "green",
          top: "0%",
          right: "5%",
          itemWidth: 10,
          itemHeight: 10,
        },
        tooltip: {},
        grid: { bottom: "20%", top: "30%", right: "10%", left: "15%" },
        xAxis: [
          {
            gridIndex: 0,
            // data: xAxisData,
            type: "category",
            data: ["左寸", "左关", "左尺", "右寸", "右关", "右尺"],
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            boundaryGap: false,
            max: this.chartdata.max,
            min: this.chartdata.min,
            interval: this.chartdata.max > 1 ? 20 : 0.2,
            position: "left",
            axisLine: {
              // lineStyle: {
              //   color: this.chartdata.colors[1]
              // },
              show: false,
            },

            axisLabel: {
              formatter: (value) => {
                if (this.type !== "maixing") {
                  return value + "%";
                } else {
                  return value;
                }
              },
            },
            textStyle: {
              color: function () {
                return "#000000";
              },
              fontSize: 9,
            },
          },
        ],
        series: [
          {
            name: "正常",
            type: "bar",
            stack: "one",
            color: "green",
            barWidth: 16,
            barGap: "20%",
            barCategoryGap: "20%",
            animation: false,
            label: {
              normal: {
                show: true,
                position: "top",
                formatter: (params) => {
                  var b = "";
                  if (this.type !== "maixing") {
                    b = this.chartdata.floats[params.dataIndex] + "%";
                  } else {
                    b = this.chartdata.floats[params.dataIndex];
                  }
                  return b;
                },
                textStyle: {
                  fontSize: 9,
                  color: "#000000",
                },
              },
            },
            itemStyle: {
              normal: {
                color: (params) => {
                  var c = this.chartdata.colours[params.dataIndex];
                  return c;
                },
                // color:'green'
              },
              emphasis: {
                barBorderWidth: 1,
                shadowBlur: 2,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: "rgba(0,0,0,0.5)",
              },
            },
            data: this.chartdata.floats,
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: { type: "line", color: "red" },
                  label: { show: false, position: "left" },
                },
              },
              data: [
                {
                  name: "",
                  yAxis: this.chartdata.upLineValue,
                  lineStyle: {
                    color: "red",
                    width: 2,
                  },
                },
                {
                  name: "",
                  yAxis: this.chartdata.downLineValue,
                  lineStyle: {
                    color: "blue",
                    width: 2,
                  },
                },
              ],
            },
          },
          {
            name: "偏沉",
            data: [],
            type: "bar",
            itemStyle: {
              color: "blue",
            },
            showBackground: false,
            backgroundStyle: {
              color: "rgba(220, 220, 220, 0.8)",
            },
            label: {
              show: false,
            },

            barWidth: 1,
          },
          {
            name: "偏浮",
            data: [],
            type: "bar",
            itemStyle: {
              color: "red",
            },
            showBackground: false,
            backgroundStyle: {
              color: "rgba(220, 220, 220, 0.8)",
            },
            label: {
              show: false,
            },

            barWidth: 1,
          },
        ],
      },
    };
  },
  watch: {},
  mounted() {
    if (this.type == "maixing") {
      this.options.xAxis[0].data = [
        "左寸",
        "",
        "左关",
        "",
        "左尺",
        "",
        "右寸",
        "",
        "右关",
        "",
        "右尺",
        "",
      ];
      this.options.series[1].name = "偏弦";
      this.options.series[2].name = "偏滑";
    } else if (this.type == "mailv") {
      this.options.series[1].name = "偏慢";
      this.options.series[2].name = "偏快";
    } else if (this.type == "maili") {
      this.options.series[1].name = "偏弱";
      this.options.series[2].name = "偏强";
    }
  },
  methods: {},
};
</script>


