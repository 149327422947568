<template>
  <div>
    <van-nav-bar title="中医健康评估综述" left-text="返回" left-arrow @click-left="() => $router.go(-1)" />
    <div>
      <div v-if="pageloading" class="we-flex-row we-flex-center we-justify-content-center">
        <van-loading type="spinner" />
      </div>
      <div v-else>
        <div class="we-bg-color we-wrap-mts">
          <div class="content-wrap overhidden">
            <div class="we-text-p15">患者信息</div>
            <van-row gutter="4">
              <van-col span="8">
                <van-field label :value="customer.realName == null || customer.realName == ''? 'X':customer.realName" readonly :colon="true" label-width="50px">
                  <template #left-icon>
                    <van-image width="15" :src="require('@assets/images/name.png')" class="we-common-mr" />
                  </template>
                </van-field>
              </van-col>
              <van-col span="8">
                <van-field label :value=" customer.sex == null ? 'X': (customer.sex == 0 ?'女':'男')" readonly :colon="true" label-width="50px">
                  <template #left-icon>
                    <van-image width="15" :src="require('@assets/images/sex.png')" class="we-common-mr" />
                  </template>
                </van-field>
              </van-col>

              <van-col span="8">
                <van-field label :value="customer.age == null ||customer.age == ''? 'X':customer.age" readonly :colon="true" label-width="50px">
                  <template #left-icon>
                    <van-image width="15" :src="require('@assets/images/age.png')" class="we-common-mr" />
                  </template>
                </van-field>
              </van-col>
            </van-row>
            <van-field label="手机号" :value="customer.phone" readonly label-width="1.8rem">
              <template #left-icon>
                <van-image width="15" :src="require('@assets/images/phone.png')" class="we-common-mr" />
              </template>
            </van-field>
            <van-field label="生成时间" :value="generateTime" readonly label-width="1.8rem">
              <template #left-icon>
                <van-image width="15" :src="require('@assets/images/time.png')" class="we-common-mr" />
              </template>
            </van-field>
          </div>
        </div>
        <div class="we-flex-row we-flex-center we-wrap-mgs">
          <div class="we-common-mr " @click="preClick" style="width:50px">
            <van-icon name="arrow-left" size="18" class="icon" color="#009018" />
          </div>
          <van-tabs v-model="activeName" swipe-threshold="3" color="#01A89E">
            <van-tab title="问诊信息" v-if="wzFlag === 1" name="wzTab"></van-tab>
            <van-tab title="脉诊信息" v-if="mzFlag === 1" name='mzTab'></van-tab>
            <van-tab title="舌诊信息" v-if="szFlag === 1" name='szTab'></van-tab>
            <van-tab title="面诊信息" v-if="faceFlag === 1" name='faceTab'></van-tab>
            <van-tab title="养生方案" v-if="ysFlag === 1" name='ysTab'></van-tab>
            <van-tab title="疾病预警" v-if="yjFlag === 1" name='yjTab'></van-tab>
            <van-tab title="医生建议" v-if="remarkFlag === 1" name='remarkTab'></van-tab>
          </van-tabs>
          <div class="we-common-ml" @click="nextClick" style="width:50px;text-align:right">
            <van-icon name="arrow" size="18" class="icon" color="#009018" />
          </div>
        </div>
        <!-- 问诊 -->
        <div v-if="activeName === 'wzTab'">
          <div class="content-wrap overhidden">
            <wz-chart :chartData="wzContent"></wz-chart>
          </div>
          <div class="content-wrap overhidden ">
            <div class="middletitle ">体质结果</div>
            <div class="margin30  we-bg-color-light text-align-c we-text-primary we-weight">{{wzContent.constitution}}{{wzContent.tendency}}</div>
          </div>
        </div>
        <!-- 脉诊 -->
        <div v-if="activeName === 'mzTab'">
          <div class="content-wrap overhidden ">
            <div class="middletitle">脉象要素分析</div>
            <table class="we-bg-color text-align-c mxtable" width="100%">
              <tr>
                <td width="20%">项目</td>
                <td width="20%">位</td>
                <td width="20%">数</td>
                <td width="20%">形</td>
                <td width="20%">势</td>
              </tr>
              <tr v-for="(item,index) in esbmAnalysisList" :key="index">
                <td v-if="item.itemType == 'left_cun'">左寸</td>
                <td v-if="item.itemType == 'left_guan'">左关</td>
                <td v-if="item.itemType == 'left_chi'">左尺</td>
                <td v-if="item.itemType == 'right_cun'">右寸</td>
                <td v-if="item.itemType == 'right_guan'">右关</td>
                <td v-if="item.itemType == 'right_chi'">右尺</td>
                <td>{{esbmAnalysisList[index].wei}}</td>
                <td>{{esbmAnalysisList[index].shu}}</td>
                <td>{{esbmAnalysisList[index].xing}}</td>
                <td>{{esbmAnalysisList[index].shi}}</td>
              </tr>
            </table>
          </div>
          <!-- <div >脉位偏差分析图</div> -->
          <div class="we-bg-color  content-wrap overhidden  we-common-pt">
            <line-chart :chartdata="result.maiweiHistogram" type="maiwei" />
            <!-- <v-chart :options="options" style="height:560px;" /> -->
          </div>
          <!-- <div >脉率偏差分析图</div> -->
          <div class="we-bg-color  content-wrap overhidden  we-common-pt">
            <line-chart :chartdata="result.mailvHistogram" type="mailv" />
            <!-- <v-chart :options="options" style="height:560px;" /> -->
          </div>
          <!-- <div >脉形偏差分析图</div> -->
          <div class="we-bg-color  content-wrap overhidden  we-common-pt">
            <line-chart :chartdata="result.maixingHistogram" type="maixing" />
            <!-- <v-chart :options="options" style="height:560px;" /> -->
          </div>
          <!-- <div >脉力偏差分析图</div> -->
          <div class="we-bg-color  content-wrap overhidden we-common-pt">
            <line-chart :chartdata="result.mailiHistogram" type="maili" />
            <!-- <v-chart :options="options" style="height:560px;" /> -->
          </div>
          <div class="content-wrap overhidden">
            <div>
              <div class="middletitle">脉象量化参数</div>
              <table class=" text-align-c mztable" width="100%">
                <tr>
                  <td>参考<br>范围</td>
                  <td>脉位<span>400-800</span></td>
                  <td>脉率<span>60-90</span></td>
                  <td>节律<span>0-0.15</span></td>
                  <td>脉力<span>500-1000</span></td>
                  <td>紧张度<span>0-0.7</span></td>
                  <td>流利度<span>0.4-1</span></td>
                </tr>
                <tr v-for="(item,index) in esbmAnalysisList" :key="index">
                  <td v-if="item.itemType == 'left_cun'">左寸</td>
                  <td v-if="item.itemType == 'left_guan'">左关</td>
                  <td v-if="item.itemType == 'left_chi'">左尺</td>
                  <td v-if="item.itemType == 'right_cun'">右寸</td>
                  <td v-if="item.itemType == 'right_guan'">右关</td>
                  <td v-if="item.itemType == 'right_chi'">右尺</td>
                  <td>{{item.mailv== '0'? '/': item.maiwei}}<span v-if="item.maiweiShow == 1">
                      <van-image width="10" :src="require('@assets/images/rise.png')" />
                    </span>
                    <span v-if="item.maiweiShow== 2 ">
                      <van-image width="10" :src="require('@assets/images/down.png')" />
                    </span>
                  </td>
                  <td>{{item.mailv== '0'?'/':item.mailv}}
                    <span v-if="item.mailvShow == 1">
                      <van-image width="10" :src="require('@assets/images/rise.png')" />
                    </span>
                    <span v-if="item.mailvShow== 2 ">
                      <van-image width="10" :src="require('@assets/images/down.png')" />
                    </span>
                  </td>
                  <td>{{item.mailv== '0'?'/':item.jielv}}
                    <span v-if="item.jielvShow == 1">
                      <van-image width="10" :src="require('@assets/images/rise.png')" />
                    </span>
                    <span v-if="item.jielvShow== 2 ">
                      <van-image width="10" :src="require('@assets/images/down.png')" />
                    </span>
                  </td>
                  <td>{{item.mailv== '0'?'/':item.maili}}
                    <span v-if="item.mailiShow == 1">
                      <van-image width="10" :src="require('@assets/images/rise.png')" />
                    </span>
                    <span v-if="item.mailiShow== 2 ">
                      <van-image width="10" :src="require('@assets/images/down.png')" />
                    </span>
                  </td>
                  <td>{{item.mailv== '0'?'/':item.jinzhangdu}}<span v-if="item.jinzhangduShow == 1">
                      <van-image width="10" :src="require('@assets/images/rise.png')" />
                    </span>
                    <span v-if="item.jinzhangduShow== 2 ">
                      <van-image width="10" :src="require('@assets/images/down.png')" />
                    </span>
                  </td>
                  <td>{{item.mailv== '0'?'/':item.liulidu}}
                    <span v-if="item.liuliduShow == 1">
                      <van-image width="10" :src="require('@assets/images/rise.png')" />
                    </span>
                    <span v-if="item.liuliduShow== 2 ">
                      <van-image width="10" :src="require('@assets/images/down.png')" />
                    </span>
                  </td>

                </tr>
              </table>
            </div>
          </div>

        </div>
        <div v-if="activeName === 'szTab'">
          <sz-report :szreport="szContent"></sz-report>
        </div>
        <!-- 面诊 -->
        <div v-if="activeName === 'faceTab'">
          <div class="content-wrap overhidden">
            <div class="middletitle">面诊图片</div>
            <div class="we-bg-color we-text-p15 text-align-c">
              <van-image width="200" height="200" fit="contain" :src="faceContent.analysisImage" />
            </div>
          </div>
          <div class="content-wrap overhidden ">
            <div class="middletitle ">面诊分析结果</div>
            <div class="margin30 ">
              <p>{{faceContent.result}}</p>
              <p>{{faceContent.lipColorDescribe}}</p>
              <p>{{faceContent.localFeatureDescribe}}</p>
              <p>{{faceContent.faceGlossDescribe}}</p>
              <p>{{faceContent.faceColorDescribe}}</p>

            </div>
          </div>
        </div>
        <div v-if="activeName === 'ysTab'">
          <van-collapse v-model="activeNames">
            <div class="content-wrap overhidden ">
              <van-collapse-item title="起居调养" name="1" label-class="we-text-color-lg">{{ysContent.workAndRestAdvice}}</van-collapse-item>
            </div>
            <div class="content-wrap overhidden">
              <van-collapse-item title="情志调养" name="2">{{ysContent.mentalCultureAdvice}}</van-collapse-item>
            </div>
            <div class="content-wrap overhidden">
              <van-collapse-item title="运动调养" name="3">{{ysContent.exerciseAdvice}}</van-collapse-item>
            </div>
            <div class="content-wrap overhidden">
              <van-collapse-item title="季节调养" name="4">{{ysContent.seasonAdvice}}</van-collapse-item>
            </div>
            <div class="content-wrap overhidden">
              <van-collapse-item title="食疗方案" name="5">
                <div v-for="item in ysContent.dietaryTherapys" :key="item.id" class="margin20b">
                  <div><b>{{item.dishName}}</b></div>
                  <div>【用料】{{item.batching}}</div>
                  <div>【功效】{{item.utility}}</div>
                </div>
              </van-collapse-item>
            </div>
            <div class="content-wrap overhidden">
              <van-collapse-item title="经穴调养" name="6">
                <div v-for="item in ysContent.acupoints" :key="item.id" class="margin20b">
                  <div class="we-flex-row">
                    <div class="we-flex">
                      <van-image width="100" :src="item.acupointUrl" />
                      <!-- <image width="100" height="100" :src="'../assets'+item.acupointUrl"/> -->
                    </div>
                    <div class="we-flex-two">{{item.acupointName}}:{{item.acupointDescribe}}</div>
                  </div>
                </div>
              </van-collapse-item>
            </div>

          </van-collapse>

        </div>
        <div v-if="activeName === 'remarkTab'">
          <div class="content-wrap overhidden ">
            <div class="middletitle">医生建议</div>
            <template v-for="(item,id) in remarkList" >
              <div :key="id" class="we-text-p15 bottomline">
                <div class="we-flex-row we-justify-content we-common-pb">
                  <div class="we-weight">{{item.createUser}}</div>
                  <div class="we-text-color-grey">{{item.createTime}}</div>
                </div>
                <div class="lineheight">
                  {{!!item.mz?item.mz+'；':''}} {{!!item.sz?item.sz+'；':''}} {{!!item.wz?item.wz+'；':''}} {{!!item.face?item.face+'；':''}} {{!!item.result?item.result+'；':''}}
                </div>
              </div>
            </template>
           
          </div>

          <!-- <jbyj-report :jbyjData="yjContent"></jbyj-report> -->

        </div>
      </div>
    </div>

    <!-- <ReportItemView ref="ReportItemView" :transmitData="transmitData" :transmittitle="transmittitle" v-if="type == 'esbm' ||type == 'jkgl'"></ReportItemView> -->
  </div>
</template>

<script>
import {
  NavBar,
  Col,
  Row,
  Field,
  Icon,
  Image as VanImage,
  Loading,
  Tab,
  Tabs,
  Collapse,
  CollapseItem,
} from "vant";
import { reportdetail } from "@api/report";
// import { clientlist } from "@api/client";
// import ClientsListModal from "@com/ClientsListModal";
// import ReportItemView from "./ReportItemView";
// import moment from "moment";
import "@assets/iconfont/iconfont.css";
import LineChart from "./components/LineChart";
import WzChart from "./components/wzChart.vue";
import SzReport from "./components/szReport.vue";
// import JbyjChart from "./components/jbyjChart.vue";

export default {
  name: "reportdetail",
  components: {
    [NavBar.name]: NavBar,
    [Col.name]: Col,
    [Row.name]: Row,
    [Field.name]: Field,
    // ReportItemView,
    LineChart,
    [Icon.name]: Icon,
    [VanImage.name]: VanImage,
    WzChart,
    SzReport,
    // JbyjChart,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Loading.name]: Loading,
  },
  data() {
    //  this.lastFetchId = 0;
    // this.fetchUser = debounce(this.fetchUser, 800);
    return {
      show: false,
      pageloading: true,
      sblbAnalysis: "",
      type: "",
      customer: "", //病人信息
      mxysAnalysis: [
        {
          wei: "",
          shu: "",
          xing: "",
          shi: "",
          itemOrder: Number,
          itemType: "",
          jielv: Number,
          jielvConfig: "",
          jielvShow: "",
          jinzhangdu: Number,
          jinzhangduConfig: "",
          jinzhangduShow: "",
          liulidu: Number,
          liuliduConfig: "",
          liuliduShow: "0",
          maili: Number,
          mailiConfig: "",
          mailiShow: "",
          mailv: Number,
          mailvConfig: "",
          mailvShow: "",
          maiwei: Number,
          maiweiConfig: "",
          maiweiShow: "",
          maxOptPulse: Number,
          optParh: [],
          optParv: [],
        },
      ], //脉象要素分析
      generateTime: "",
      title: "", //报告名称
      transmitData: {
        wei: "",
        shu: "",
        xing: "",
        shi: "",
        itemOrder: Number,
        itemType: "",
        jielv: Number,
        jielvConfig: "",
        jielvShow: "",
        jinzhangdu: Number,
        jinzhangduConfig: "",
        jinzhangduShow: "",
        liulidu: Number,
        liuliduConfig: "",
        liuliduShow: "0",
        maili: Number,
        mailiConfig: "",
        mailiShow: "",
        mailv: Number,
        mailvConfig: "",
        mailvShow: "",
        maiwei: Number,
        maiweiConfig: "",
        maiweiShow: "",
        maxOptPulse: Number,
        optParh: [0, 0, 0, 0, 0],
        optParv: [0, 0, 0, 0, 0],
        optPulseLen: Number,
      },
      transmittitle: "",

      activeName: "",
      activeNames: ["1"],
      activeJbNames: ["gxy"],
      esbmAnalysisList: [],
      result: {},
      wzFlag: 0,
      mzFlag: 0,
      szFlag: 0,
      ysFlag: 0,
      yjFlag: 0,
      remarkFlag:0,
      remarkList: [],
    };
  },
  computed: {},
  methods: {
    //显示子组件
    showReport(i) {
      if (this.mxysAnalysis[i].optPulseLen !== 0) {
        switch (i) {
          case 0:
            this.transmittitle = "左寸";
            break;
          case 1:
            this.transmittitle = "左关";
            break;
          case 2:
            this.transmittitle = "左尺";
            break;
          case 3:
            this.transmittitle = "右寸";
            break;
          case 4:
            this.transmittitle = "右关";
            break;
          case 5:
            this.transmittitle = "右尺";
        }
        this.transmitData = this.mxysAnalysis[i];
        this.$refs.ReportItemView.show();
      }
    },
    //获取报告详情
    getDetail(id) {
      reportdetail(id).then((res) => {
        if (res.respCode == 200) {
          this.pageloading = false;
          this.customer = res.result.customer; //病人信息
          this.generateTime = res.result.generateTime; //信息
          this.result = res.result;
          this.esbmAnalysisList = res.result.esbmAnalysisList;
          this.wzFlag = res.result.constitutionResult == null ? 0 : 1;
          this.wzContent = res.result.constitutionResult;
          this.szFlag = res.result.tongueAnalysisVo == null ? 0 : 1;
          this.szContent = res.result.tongueAnalysisVo;
          this.mzFlag = res.result.esbmAnalysisList == null ? 0 : 1;
          this.ysFlag = res.result.medicalHealthRegimen == null ? 0 : 1;
          this.ysContent = res.result.medicalHealthRegimen;
          this.yjFlag = res.result.gtgAnalysis == null ? 0 : 1;
          this.yjContent = res.result.gtgAnalysis;
          this.faceFlag = res.result.faceAnalysisVo == null ? 0 : 1;
          this.faceContent = res.result.faceAnalysisVo;
          this.remarkFlag = res.result.remarkList == null ? 0 : 1;
          this.remarkList = res.result.remarkList;
        } else {
          this.$toast(res.respMsg);
          this.pageloading = false;
        }
      });
    },

    preClick() {
      const names = [
        { name: "wzTab", flag: this.wzFlag },
        { name: "mzTab", flag: this.mzFlag },
        { name: "szTab", flag: this.szFlag },
        { name: "faceTab", flag: this.faceFlag },
        { name: "ysTab", flag: this.ysFlag },
        { name: "yjTab", flag: this.yjFlag },
        { name: "remarkTab", flag: this.remarkFlag },
      ];
      let newnames = names.filter((item) => item.flag == 1);
      let index = newnames.findIndex((item) => item.name == this.activeName);
      if (index == 0) {
        return false;
      } else {
        this.activeName = newnames[index - 1].name;
      }
    },
    nextClick() {
      const names = [
        { name: "wzTab", flag: this.wzFlag },
        { name: "mzTab", flag: this.mzFlag },
        { name: "szTab", flag: this.szFlag },
        { name: "faceTab", flag: this.faceFlag },
        { name: "ysTab", flag: this.ysFlag },
        { name: "yjTab", flag: this.yjFlag },
        { name: "remarkTab", flag: this.remarkFlag },
      ];
      let newnames = names.filter((item) => item.flag == 1);
      let index = newnames.findIndex((item) => item.name == this.activeName);
      if (index == newnames.length - 1) {
        return false;
      } else {
        this.activeName = newnames[index + 1].name;
      }
    },
    jbRiskTip(v, n) {
      var m = "";
      if (v >= 0 && v < 33.3) {
        m = "继续保持良好生活方式。";
      } else if (v >= 33.3 && v < 66.6) {
        if (n === "gxy") {
          m = "建议关注血压指标并及时干预。";
        }
        if (n === "tnb") {
          m = "建议关注血糖指标并及时干预。";
        }
        if (n === "gxb") {
          m = "建议关注心脏功能指标并及时干预。";
        }
      } else {
        m = "建议及时进行相关医学检查并及时干预。";
      }
      return m;
    },
  },
  mounted() {
    this.pageloading = true;
    const id = this.$route.params.id;
    this.type = this.$route.params.type;
    this.getDetail(id);
    document.body.scrollIntoView();
  },
};
</script>
<style lang="less" scoped>
.mxtable {
  // margin: 20px 10px 30px;
  padding: 10px;
  td {
    height: 60px;
    line-height: 30px;
  }
  tr:first-child {
    span {
      display: block;
      font-size: 10px;
    }
    td {
      height: 60px;
      line-height: 30px;
    }
  }
  tr:nth-child(odd) {
    background-color: #fff;
    td {
      background-color: #baf7c4;
      color: #3f3f3f;
    }
  }
  tr:nth-child(even) {
    td {
      background-color: #deffe3;
      color: #3f3f3f;
    }
  }
}

.chartW {
  width: 100%;
  height: 280px;
  // max-height: 60px;
}

.line_wrap {
  height: 60px;
  width: 100%;
  .line_item {
    height: 60px;
    width: 100%;
  }
}
.icon {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  background-color: #deffe3;
}
.margin30 {
  margin: 15px 15px 45px 15px;
  font-size: 16px;
  padding: 20px 5px;
}

.mztable {
  line-height: 15px;
  // margin: 0 5px;
  padding: 10px;
  background-color: #fff;
  td {
    height: 60px;
    line-height: 30px;
  }
  tr:first-child {
    span {
      display: block;
      font-size: 10px;
    }
    td {
      height: 60px;
      line-height: 30px;
    }
  }
  tr:nth-child(odd) {
    background-color: #fff;
    td {
      background-color: #f3f3f3;
      color: #3f3f3f;
    }
  }
  tr:nth-child(even) {
    td {
      background-color: #fff;
      color: #3f3f3f;
    }
  }
}
</style>
<style lang="less">
.van-tab--active {
  background-color: #01a89e;
  color: #fff;
  border-radius: 30px;
}
.van-tabs--line {
  // flex: 1;
  .van-tab__text {
    padding: 0 5px;
  }
}
.van-tabs--line .van-tabs__wrap {
  height: 30px;
}
.van-tabs__line {
  display: none;
}
.van-collapse-item {
  .van-cell__right-icon {
    background-color: #009018;
    width: 30px;
    height: 30px;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    :before {
      text-align: center;
    }
  }
}
.van-collapse-item__content {
  color: #222;
}
.bottomline{
  border-bottom: 1px dashed #e0e0e0;
}
</style>